
export const setCookie = (name, value, options = {}) => {
  if (process.browser) {
    const cookieParts = [`${name}=${value}`]

    Object.entries(options).forEach(([key, val]) => {
      if (key === 'maxAge') {
        cookieParts.push(`Max-Age=${val}`)
      } else if (key === 'secure' && val === true) {
        cookieParts.push('Secure')
      } else if (key === 'sameSite') {
        cookieParts.push(`SameSite=${val}`)
      } else {
        cookieParts.push(`${key}=${val}`)
      }
    })

    document.cookie = cookieParts.join(';')
  } else {
    // Server-side logic if needed
  }
}

export const getCookie = (name) => {
  if (process.browser) {
    return document.cookie
      .split(';')
      .map(cookie => cookie.trim() && cookie.split('='))
      .find(cookie => cookie[0] === name)?.[0]
  } else {
    //
  }
}

export const cookieStrToObj = cookieString => cookieString
  .split(';')
  .reduce((cookieObj, cookie) => {
    const [key, value] = decodeURIComponent(cookie).trim().split('=')

    return {
      ...cookieObj,
      [key]: value,
    }
  }, {})
